import { default as abuseReportsg0BVCCIKsNMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93YbPmolWfK8Meta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexFwS06rqYqeMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/index.vue?macro=true";
import { default as moderationA66FY1ZZ3yMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/moderation.vue?macro=true";
import { default as pendingBanswf3XvIddL9Meta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/pendingBans.vue?macro=true";
import { default as profilesLIzPVV3JxMMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/profiles.vue?macro=true";
import { default as expensesWmbXAqr1hSMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/timesheets/expenses.vue?macro=true";
import { default as index1JHDbxAg9PMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewxlFpFVW5HEMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingRqcrbvc1KRMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingPeAodM5mmSMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/translations/missing.vue?macro=true";
import { default as usersoCe2v5luFRMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/users.vue?macro=true";
import { default as apiJWHEXCU4GGMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/api.vue?macro=true";
import { default as _91slug_93naaTzeRUUVMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/blog/[slug].vue?macro=true";
import { default as indexyMGUMCEayJMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93vM6ts7TBHmMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93pTDXpiMSt2Meta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminri8etS1itpMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/census/admin.vue?macro=true";
import { default as indextZ3Z8wFnVBMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/census/index.vue?macro=true";
import { default as contactIQM3f7ji7rMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/contact.vue?macro=true";
import { default as designJg7khXQgwCMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/design.vue?macro=true";
import { default as english1YzdZyO06dMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/english.vue?macro=true";
import { default as faqvgGq8hwd3jMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/faq.vue?macro=true";
import { default as inclusive8KFdKCLezUMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/inclusive.vue?macro=true";
import { default as indexvxLWNVnEiGMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/index.vue?macro=true";
import { default as licensej0gwQFZQHZMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/license.vue?macro=true";
import { default as academicacIRHYrr5YMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/links/academic.vue?macro=true";
import { default as index0xwpcsceI7Meta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/links/index.vue?macro=true";
import { default as mediaCeGMLcDTEXMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/links/media.vue?macro=true";
import { default as translinguisticsm9fR2mAwooMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/links/translinguistics.vue?macro=true";
import { default as zine1aZrK3POviMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/links/zine.vue?macro=true";
import { default as namesP3sooI8xxaMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/names.vue?macro=true";
import { default as index4lVToQ9UWWMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/nouns/index.vue?macro=true";
import { default as templatespIh2JOq7BFMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/nouns/templates.vue?macro=true";
import { default as peopleYIOJXk9JDbMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/people.vue?macro=true";
import { default as privacyZRM9PPPzigMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/privacy.vue?macro=true";
import { default as _91username_93esjREZEfIDMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93Lr3YtrH12TMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/profile/card-[username].vue?macro=true";
import { default as editorRCApXNNtJjMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/profile/editor.vue?macro=true";
import { default as anyjKa0RCm4vXMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/any.vue?macro=true";
import { default as askublsNEMB1FMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/ask.vue?macro=true";
import { default as avoidingDz2zHYxlbeMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/avoiding.vue?macro=true";
import { default as indexieNB2kGLm6Meta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/index.vue?macro=true";
import { default as mirrorR2kjtya8B7Meta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/mirror.vue?macro=true";
import { default as pronounMRz58SUEnGMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesPYO2fRxa0LMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/sources.vue?macro=true";
import { default as team1iXA7NCaofMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/team.vue?macro=true";
import { default as terminologythjG4UuYdOMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/terminology.vue?macro=true";
import { default as termsu7rOjdSgKoMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/terms.vue?macro=true";
import { default as userBDDcBtMUmzMeta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/user.vue?macro=true";
import { default as workshopslEbvNdGUN3Meta } from "/home/admin/www/de.pronouns.page/release/20241116155013/pages/workshops.vue?macro=true";
import { default as deklinationsmusterHoOdm86sCDMeta } from "~/data/nouns/deklinationsmuster.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsg0BVCCIKsNMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBanswf3XvIddL9Meta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiJWHEXCU4GGMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93naaTzeRUUVMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexyMGUMCEayJMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93vM6ts7TBHmMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93pTDXpiMSt2Meta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminri8etS1itpMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indextZ3Z8wFnVBMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactIQM3f7ji7rMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: english1YzdZyO06dMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqvgGq8hwd3jMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusive8KFdKCLezUMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicacIRHYrr5YMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: index0xwpcsceI7Meta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaCeGMLcDTEXMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsm9fR2mAwooMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zine1aZrK3POviMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesP3sooI8xxaMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index4lVToQ9UWWMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatespIh2JOq7BFMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleYIOJXk9JDbMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyZRM9PPPzigMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93esjREZEfIDMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93Lr3YtrH12TMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorRCApXNNtJjMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyjKa0RCm4vXMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askublsNEMB1FMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingDz2zHYxlbeMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexieNB2kGLm6Meta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorR2kjtya8B7Meta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounMRz58SUEnGMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesPYO2fRxa0LMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: team1iXA7NCaofMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologythjG4UuYdOMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsu7rOjdSgKoMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userBDDcBtMUmzMeta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopslEbvNdGUN3Meta || {},
    component: () => import("/home/admin/www/de.pronouns.page/release/20241116155013/pages/workshops.vue")
  },
  {
    name: "nouns-deklinationsmuster",
    path: "/deklinationsmuster",
    component: () => import("~/data/nouns/deklinationsmuster.vue")
  }
]